import React, { useEffect, useState } from 'react'
import { GoogleMap, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { ApiKey } from '../common/Apikey'
import Footer from './Footer';
import '../App.css'
import Redmarker from '../assets/image/red_marker.png'
import Greenmarker from '../assets/image/green_marker.png'

const center = {
    lat: 1.351068878585781,
    lng: 103.872059191975
};

function MyComponent() {
    const [pickupPosition, setPickupPosition] = useState(null);
    const [dropoffPosition, setDropoffPosition] = useState(null);
    const [stop1Position, setStop1Position] = useState(null)
    const [stop2Position, setStop2Position] = useState(null)

    const handleStorageChange = () => {
        const storedPickupPosition = localStorage.getItem('pickupPosition');
        const storedDropoffPosition = localStorage.getItem('dropoffPosition');
        const storedStop1Position = localStorage.getItem('stopPosition0');
        const storedStop2Position = localStorage.getItem('stopPosition1');

        if (storedPickupPosition) {
            setPickupPosition(JSON.parse(storedPickupPosition));
        } else {
            setPickupPosition(null)
        }

        if (storedDropoffPosition) {
            setDropoffPosition(JSON.parse(storedDropoffPosition));
        } else {
            setDropoffPosition(null)
        }

        if (storedStop1Position) {
            setStop1Position(JSON.parse(storedStop1Position))
        } else {
            setStop1Position(null)
        }

        if (storedStop2Position) {
            setStop2Position(JSON.parse(storedStop2Position))
        } else {
            setStop2Position(null)
        }
    }

    useEffect(() => {
        window.addEventListener('storage', handleStorageChange);
    }, []);

    // const { isLoaded } = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey: ApiKey.google_map_key
    // })

    let RediconMarker = new window.google.maps.MarkerImage(
        Redmarker,
        null, /* size is determined at runtime */
        null, /* origin is 0,0 */
        null, /* anchor is bottom center of the scaled image */
        new window.google.maps.Size(40, 40)
    );

    let GreeniconMarker = new window.google.maps.MarkerImage(
        Greenmarker,
        null, /* size is determined at runtime */
        null, /* origin is 0,0 */
        null, /* anchor is bottom center of the scaled image */
        new window.google.maps.Size(40, 40)
    );

    return (
        <GoogleMap
            // mapContainerStyle={containerStyle}
            mapContainerClassName='mapBg'
            center={pickupPosition != null ? pickupPosition : center}
            zoom={pickupPosition != null ? 11 : 12}
            options={{
                mapTypeControl: false,
                fullscreenControl: false,
                streetViewControl: false,
            }}
        >
            {pickupPosition && (
                <Marker
                    position={pickupPosition}
                    icon={RediconMarker}
                />
            )}
            {dropoffPosition && (
                <Marker
                    position={dropoffPosition}
                    icon={GreeniconMarker}
                />
            )}
            {stop1Position && (
                <Marker
                    position={stop1Position}
                    icon={RediconMarker}
                />
            )}
            {stop2Position && (
                <Marker
                    position={stop2Position}
                    icon={RediconMarker}
                />
            )}
            {pickupPosition && dropoffPosition && (
                <Polyline
                    path={[pickupPosition, dropoffPosition]}
                    options={{
                        strokeColor: 'black',
                        strokeOpacity: 1,
                        strokeWeight: 3,
                    }}
                />
            )}
            {pickupPosition && stop1Position && stop1Position != null && dropoffPosition && (
                <Polyline
                    path={[pickupPosition, stop1Position, dropoffPosition]}
                    options={{
                        strokeColor: 'black',
                        strokeOpacity: 1,
                        strokeWeight: 3,
                    }}
                />
            )}
            {pickupPosition && stop2Position && stop2Position != null && dropoffPosition && (
                <Polyline
                    path={[pickupPosition, stop1Position, dropoffPosition]}
                    options={{
                        strokeColor: 'black',
                        strokeOpacity: 1,
                        strokeWeight: 3,
                    }}
                />
            )}
            {pickupPosition && stop1Position && stop1Position != null && stop2Position && stop2Position != null && dropoffPosition && (
                <Polyline
                    path={[pickupPosition, stop1Position, stop2Position, dropoffPosition]}
                    options={{
                        strokeColor: 'black',
                        strokeOpacity: 1,
                        strokeWeight: 3,
                    }}
                />
            )}
            <></>
        </GoogleMap>
    )
}

export default React.memo(MyComponent)