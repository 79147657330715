import React, { useEffect, useState } from 'react'
import '../../styles/EditBook.css'

function NotFound() {

    const [digit1, setDigit1] = useState('');
    const [digit2, setDigit2] = useState('');
    const [digit3, setDigit3] = useState('');
    const [timer, setTimer] = useState(30);
    let i = 0;

    useEffect(() => {
        const loop3 = setInterval(() => {
            if (i > 40) {
                clearInterval(loop3);
                setDigit3('4');
            } else {
                setDigit3(randomNum());
                i++;
            }
        }, timer);

        const loop2 = setInterval(() => {
            if (i > 80) {
                clearInterval(loop2);
                setDigit2('0');
            } else {
                setDigit2(randomNum());
                i++;
            }
        }, timer);

        const loop1 = setInterval(() => {
            if (i > 100) {
                clearInterval(loop1);
                setDigit1('4');
            } else {
                setDigit1(randomNum());
                i++;
            }
        }, timer);

        return () => {
            clearInterval(loop1);
            clearInterval(loop2);
            clearInterval(loop3);
        };
    }, []);

    function randomNum() {
        return Math.floor(Math.random() * 9) + 1;
    }

    return (
        <div>
            <div className="error">
                <div className="container-floud">
                    <div className="col-xs-12 ground-color text-center">
                        <div className="container-error-404">
                            <div className="clip">
                                <div className="shadow"><span className="digit thirdDigit">{digit3}</span></div>
                            </div>
                            <div className="clip">
                                <div className="shadow"><span className="digit secondDigit">{digit2}</span></div>
                            </div>
                            <div className="clip">
                                <div className="shadow"><span className="digit firstDigit">{digit1}</span></div>
                            </div>
                            <div className="msg">OH!<span className="triangle"></span></div>
                        </div>
                        <h2 className="h1">Sorry! Page Unavaliable</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound