import React, { useState } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import '../../App.css'
import $ from 'jquery';
import { ApiCall } from '../../common/Apikey';

function EditPaynow(props) {

    // const [clientSecret, setClientSecret] = useState("")
    const stripe = useStripe();
    const elements = useElements();

    const PayNowModel = async (event) => {
        event.preventDefault();

        $('#messageConfirm').click()
        let data = {
            booking_data: props.editParams,
            booking_id: props.bookingId,
            total_amount: props.amount
        }
        await ApiCall('GuestEditgenerateAccessToken', data).then((data) => {
            if (data.message == "success") {
                let bookId = data.result.prebooking_id;

                setTimeout(() => {
                    $('#confirmClose').click();
                    PayNow(bookId);
                }, 3000);
            } else {
                toast.error('Payment Failed, Please Try Again....', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(() => { refreshPage() }, 5000)
            }
        }).catch(err => console.log(err));
    }

    const PayNow = async (idBook) => {
        // event.preventDefault()

        let data = {
            id: idBook,
            amount: props.amount,
            email: props.email,
            phone_number: props.phoneNumber
        }
        var clientSecret = "";
        await ApiCall('StripePaynow', data).then((data) => {
            if (data.message === "success") {
                // setClientSecret(data.client_secret)
                clientSecret = data.client_secret
            } else {
                console.log('error')
            }
        }).catch(err => console.log(err));

        // const clientSecret = "pi_3NRETUAf6IJVLww31k179sBl_secret_sB6oWr44rd3tHL13xt1WJY9G8";
        if (clientSecret) {
            const result = await stripe.confirmPayNowPayment(clientSecret);
            const { paymentIntent } = result;
            if (paymentIntent && paymentIntent.status == 'succeeded') {
                $('#VDclose').click();
                setTimeout(function () {
                    props.getSuccess('sucess')
                }, 2000);

            } else {
                $('#VDclose').click();
                setTimeout(function () {
                    props.getSuccess('error')
                }, 2000);
            }
        } else {
            props.getSuccess('error')
        }
    }

    return (
        <div>
            <form>
                <button className="btn rounded-pill cartBtn" id="submit" onClick={PayNowModel}>Confirm</button>
            </form>
        </div>
    );
}

export default EditPaynow;