import React from 'react'
import '../../styles/EditBook.css'

function NoEdit() {
    return (
        <div id="NoEdit">
            <div className="container" id="center">
                <p style={{ textAlign: 'center' }} id="text">Sorry. You are unable to edit the trip as it is less than 24 hours from now.</p>
            </div>
        </div>
    )
}

export default NoEdit