import { act } from "react-dom/test-utils";
import { combineReducers } from "redux";

function cartReducer(state = { AddCart: [] }, action) {
  switch (action.type) {
    case "addToCart":
        return { 
          ...state,
          AddCart:[...state.AddCart,action.payload] 
        }
    case "EmptyCart":
      return {
        AddCart: []
      }
    case "RemoveCart":
      const updatedObjects = [...state.AddCart];
      updatedObjects.splice(action.payload, 1);
      return {
        ...state,
        AddCart:updatedObjects
      }
    default:
        return state;
  }
}

function VerifyReducer(state = { Verify: 0 }, action) {
  switch (action.type) {
    case "VerifyDetails":
        return { 
          Verify: action.payload.verify_status
        }
    case "EmptyVerify":
      return {
        Verify: action.payload.verify_status
      }
    default:
        return state;
  }
}

function SaveReducer(state = { Personal: {} }, action) {
  switch (action.type) {
    case "StoreDetails":
        return { 
          Personal: action.payload,
        }
    case "RemoveDetails":
      return {
        Personal: {}
      }
    default:
        return state;
  }
}

const Reducer = combineReducers({
  cart :cartReducer,
  verify : VerifyReducer,
  saveData: SaveReducer,
});

export default Reducer;
