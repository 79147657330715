import React, { useRef, useState } from 'react'
import { ApiCall } from '../common/Apikey';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { ThreeDots } from 'react-loader-spinner';

function VerifyModel(props) {

    const [Eotp, setEOtp] = useState("")
    const [Potp, setPOtp] = useState("")
    const [emailbtn, setemailbtn] = useState(0)
    const [phonebtn, setphonebtn] = useState(0)

    const inputRefMail = useRef(null);
    const inputRefMobile = useRef(null);

    const EmailClose = () => {
        setEOtp('')
        inputRefMail.current.value = '';
    }

    const MobileClose = () => {
        setPOtp('')
        inputRefMobile.current.value = '';
    }

    const inputChange = (event) => {
        let { name, value } = event.target;
        if(name == 'Eotp'){
            setEOtp(value)
        }else{
            setPOtp(value)
        }
    }

    const verifyEmail = async (event) => {
        event.preventDefault();
        setemailbtn(1)
        if(Eotp.trim() == ""){
            setemailbtn(0)
            toast.error('Enter OTP', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return false
        }
        let data = {
            type: 'email',
            otp: Eotp,
            email: props.email
        };
        await ApiCall('VerifyGuestOtp', data).then((data) => {
            if (data.message === "success") {
                $('#Eclose').click()
                setemailbtn(0)
                props.getSuccess('sucessEmail')
            } else if (data.message == 'otp mistach') {
                setemailbtn(0)
                toast.error('OTP Mismatch', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                setemailbtn(0)
                toast.error('Something went wrong please try again', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }).catch(err => console.log(err));
    }

    const VerifyPhone = async (event) => {
        event.preventDefault();
        setphonebtn(1)

        if(Potp.trim() == ""){
            setphonebtn(0)
            toast.error('Enter OTP', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return false
        }

        let data = {
            type: 'mobile',
            otp: Potp,
            phone_number: props.phone
        };
        await ApiCall('VerifyGuestOtp', data).then((data) => {
            if (data.message === "success") {
                $('#Pclose').click()
                setphonebtn(0)
                props.getSuccess('sucessMobile')
            } else if (data.message == 'otp mistach') {
                setphonebtn(0)
                toast.error('OTP Mismatch', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                setphonebtn(0)
                toast.error('Something went wrong please try again', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }).catch(err => console.log(err));
    }

    return (
        <>
            <div>
                <div className="modal fade" id="VerifyEmail" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="VerifyEmailLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header add-modal">
                                <h5 className="modal-title" id="staticBackdropLabel">OTP Verify</h5>
                                <i className="fa fa-window-close" data-bs-dismiss="modal" aria-label="Close" id="Eclose" onClick={EmailClose}></i>
                            </div>
                            <form method="post" autoComplete="off">
                                <div className="modal-body">
                                    <div className="mb-3 emp-add-input">
                                        <label className="form-label" id="otp_text">Enter OTP</label>
                                        <input type="text" name="Eotp" className="form-control" placeholder="OTP" required onChange={inputChange} ref={inputRefMail} defaultValue={Eotp}/>
                                    </div>
                                    {/* <a href="#" className="resend_otp" id="empresend_otp">Resend OTP</a> */}
                                </div>
                                <div className="modal-footer">
                                    {emailbtn == 1 ? <div className='d-flex justify-content-end'><ThreeDots
                                        height="40"
                                        width="40"
                                        radius="9"
                                        color="#ff8901"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    /></div> : <button type="submit" id="employee_otp_button" className="add-emp-btn" onClick={verifyEmail}>Submit</button>}

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* mobile verify */}
            <div>
                <div className="modal fade" id="VerifyPhone" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="VerifyPhoneLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header add-modal">
                                <h5 className="modal-title" id="staticBackdropLabel">OTP Verify</h5>
                                <i className="fa fa-window-close" data-bs-dismiss="modal" aria-label="Close" id="Pclose" onClick={MobileClose}></i>
                            </div>
                            <form method="post" autoComplete="off">
                                <div className="modal-body">
                                    <div className="mb-3 emp-add-input">
                                        <label className="form-label" id="otp_text">Enter OTP</label>
                                        <input type="text" name="Potp" className="form-control" placeholder="OTP" required onChange={inputChange} ref={inputRefMobile} defaultValue={Potp} />
                                    </div>
                                    {/* <a href="#" className="resend_otp" id="empresend_otp">Resend OTP</a> */}
                                </div>
                                <div className="modal-footer">
                                    {phonebtn == 1 ? <div className='d-flex justify-content-end'><ThreeDots
                                        height="40"
                                        width="40"
                                        radius="9"
                                        color="#ff8901"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    /></div> : <button type="submit" id="employee_otp_button" className="add-emp-btn" onClick={VerifyPhone}>Submit</button>}

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifyModel